import React, { useState, useEffect } from "react";
import axios from "axios";
import SuperAgentPasswordVerifyModal from "../../../SuperAgentPasswordVerifyModal/SuperAgentPasswordVerifyModal";
import { ToastContainer, toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import jsPDF from "jspdf";
import "jspdf-autotable";
import moment from "moment";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

function AddSavingSuperAgentModal({ custId }) {
  let token = localStorage.getItem("SuperAgentChromepayToken");
  const [showModal, setShowModal] = useState(false);
  const [showCancelModal, setshowCancelModal] = useState(false);
  const [SavingsHistory, setSavingsHistory] = useState([]);
  const [pageCount, setpageCount] = useState(0);
  const [transId, settransId] = useState("");

  const [formState, setFormState] = useState({
    withAmount: "",
    TypeOfsavings: "",
    SavingPrice: 0,
    Interestpercentage: 0,
    SavingPrice: 0,
    custId,
    filterData: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSavingTypeChange = (e) => {
    const { value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      TypeOfsavings: value,
    }));
    interestRateCount(value); // Fetch interest rate when the saving type changes
  };

  const interestRateCount = async (savingType) => {
    try {
      const resp = await axios.post(
        `/v1/super-Agent/get-saving-interest`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (resp.status) {
        const { data } = resp.data;

        let interestRate;
        switch (savingType) {
          case "mandatory":
            interestRate = data.mandatory;
            break;
          case "volantary":
            interestRate = data.voluntary;
            break;
          default:
            interestRate = data;
        }

        setFormState((prevState) => ({
          ...prevState,
          Interestpercentage: interestRate,
        }));
      } else {
        toast.error(resp.data.msg);
      }
    } catch (error) {
      toast.error("Error fetching interest rates");
      console.error("Error:", error);
    }
  };

  const GetSharePrice = (e) => {
    axios
      .post(
        `/v1/super-Agent/get-saving-share-per`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((resp) => {
        if (resp.status) {
          let data = resp.data;

          if (data.status) {
            setFormState((prevState) => ({
              ...prevState,
              SavingPrice: data.data,
            }));

            return;
          } else {
            toast.error(data.msg);
          }
        } else {
          toast.error("error");
        }
      });
  };

  const AddSaving = async (e) => {
    const Payload = {
      custId,
      accType: formState.TypeOfsavings,
      amount: parseInt(formState.withAmount),
      interest: formState.Interestpercentage,
    };

    try {
      const resp = await axios.post(`/v1/DID/add-union-saving`, Payload, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success("Saving added successfully");
      SavingDeatailshistory();
    } catch (error) {
      toast.error("Error adding saving");
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if (formState.TypeOfsavings) {
      interestRateCount(formState.TypeOfsavings);
    }
  }, [formState.TypeOfsavings]);

  useEffect(() => {
    GetSharePrice();
    SavingDeatailshistory();
  }, []);

  useEffect(() => {
    SavingDeatailshistory();
  }, [formState?.filterData]);

  const handlerformSubmit = (e) => {
    e.preventDefault();
    setShowModal(true);
  };

  const SavingDeatailshistory = () => {
    axios
      .post(
        `/v1/super-Agent/get-saving-logs`,
        {
          custId,
          page: 1,
          type: "deposite",
          savingType: formState?.filterData,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setSavingsHistory(saveData);

        const total = resp.data.totalRaw;
        const totalPage = Math.ceil(total / 10);
        console.log("=-=-=->", { totalPage });
        setpageCount(totalPage || 0);

        console.log("Usersaving", total);
      });
  };

  const handlePageClick = async (data) => {
    const page = data.selected + 1;
    const commentsFormServer = await axios
      .post(
        `/v1/super-Agent/get-saving-logs`,
        {
          custId,
          page: page,
          type: "deposite",
          savingType: formState?.filterData,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((resp) => {
        let saveData = resp.data.data;
        setSavingsHistory(saveData);
      });
  };

  const handleCancelTransaction = async () => {
    try {
      const response = await axios.post(
        `/v1/super-Agent/cancel-saving-trans`,
        { transId },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      if (response.data.status) {
        toast.success(response.data.msg);
        SavingDeatailshistory();
      } else {
        toast.error(response.data.msg);
      }
    } catch (error) {}
  };

  const handleDownloadInvoice = (invoiceData) => {
    // Create a new jsPDF instance
    const doc = new jsPDF();
    console.log({ invoiceData });

    // Set up styling for the PDF
    doc.setFont("helvetica", "bold");
    doc.setFontSize(18);

    const width = doc.internal.pageSize.getWidth();

    // Calculate the width of the text for the invoice heading
    const headingTextWidth =
      (doc.getStringUnitWidth("Your Saving Deposit Invoice") *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const headingX = (width - headingTextWidth) / 2;

    // Add the invoice heading
    doc.text("Your Saving Deposit Invoice", headingX, 20);

    // Format the date as dd mm yyyy
    const formattedDate = new Date(invoiceData.activityDate).toLocaleDateString(
      "en-GB",
      {
        day: "2-digit",
        month: "short",
        year: "numeric",
      }
    );

    // Add the date on the right side
    const dateTextWidth =
      (doc.getStringUnitWidth(`Date: ${formattedDate}`) *
        doc.internal.getFontSize()) /
      doc.internal.scaleFactor;
    const dateX = width - dateTextWidth - 10; // Subtracting 10 for padding
    doc.setFont("helvetica", "normal");
    doc.setFontSize(15);
    doc.text(`Date: ${formattedDate}`, dateX, 40);

    // Set up styling for the section headings
    doc.setFont("helvetica", "bold");
    doc.setFontSize(20);

    // Add the customer details heading with left alignment
    doc.text("Customer Details", 10, 60);

    // Add the customer details
    doc.setFont("helvetica", "normal");
    doc.setFontSize(15);
    doc.text(`Customer ID: ${invoiceData.custId}`, 10, 80);
    doc.text(`Customer Name: ${invoiceData.cName}`, 10, 90);
    doc.text(`Customer Phone: ${invoiceData.cPhone}`, 10, 100);

    // Add the saving details heading with left alignment
    doc.text("Saving Details", 10, 120);

    // Add the share details table

    doc.autoTable({
      startY: 140,
      head: [
        [
          "Share ID",
          "Saving ID",
          "Share Price (ETB)",
          "Saving Price (ETB)",
          "Share Quantity",
          "Payment Method",
        ],
      ],
      body: [
        [
          invoiceData.shareId,
          invoiceData.savingId,
          ` ${invoiceData.sharePrice}`,
          `${invoiceData.sharePrice || 0}`,
          invoiceData.quantity,
          invoiceData.payType,
        ],
      ],
    });

    // Add the total share amount and total saving amount on the right side in a smaller font size
    const totalTextSize = 10;
    const totalX = width - 60; // Adjust as needed
    doc.setFontSize(totalTextSize);
    doc.text(
      `Total Share Amount (ETB):  ${invoiceData.shareAmount || 0}`,
      totalX,
      doc.autoTable.Previous.finalY + 10
    );
    doc.text(
      `Total Saving Amount (ETB):  ${invoiceData.totalAmount}`,
      totalX,
      doc.autoTable.Previous.finalY + 20
    );

    // Save the PDF and initiate download
    doc.save("invoice.pdf");
  };

  return (
    <>
      <div className="tab-pane fade active show" id="overview" role="tabpanel">
        <div className="row">
          <div className="col-lg-6">
            <div className="card mb-5 mb-xl-10" id="kt_profile_details_view">
              <div className="card-header cursor-pointer">
                <div className="card-body p-9">
                  <div
                    id="kt_account_settings_profile_details"
                    className="collapse show"
                  >
                    <form
                      id="kt_account_profile_details_form"
                      className="form"
                      onSubmit={handlerformSubmit}
                    >
                      <div className="card-body border-top p-9">
                        <div className="row mb-8">
                          <div className="col-lg-12 mb-8">
                            <div className="row">
                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                Amount (ETB)
                              </label>
                              <div className="col-lg-8 fv-row">
                                <input
                                  type="number"
                                  name="withAmount"
                                  className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                  placeholder="Amount"
                                  step="any"
                                  required
                                  min={1}
                                  value={formState.withAmount}
                                  onChange={handleInputChange}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-lg-12 mb-8">
                            <div className="row">
                              <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                Types of Saving
                              </label>
                              <div className="col-lg-8 fv-row">
                                <select
                                  name="TypeOfsavings"
                                  required
                                  className="form-select flex-grow-1"
                                  value={formState.TypeOfsavings}
                                  onChange={handleSavingTypeChange}
                                >
                                  <option value="">Select Saving Type</option>
                                  <option value="mandatory">Mandatory</option>
                                  <option value="volantary">Voluntary</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          {formState.TypeOfsavings === "mandatory" && (
                            <>
                              <div className="col-lg-12 mb-8">
                                <div className="row">
                                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                    Share Amount (ETB)
                                  </label>
                                  <div className="col-lg-8 fv-row">
                                    <input
                                      type="number"
                                      name="shareAmount"
                                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                      placeholder="Amount"
                                      step="any"
                                      required
                                      value={
                                        (formState.withAmount *
                                          formState.SavingPrice) /
                                        100
                                      }
                                      readOnly
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-12 mb-8">
                                <div className="row">
                                  <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                                    Total Saving Amount (ETB)
                                  </label>
                                  <div className="col-lg-8 fv-row">
                                    <input
                                      type="number"
                                      name="totalSavingAmount"
                                      className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                      placeholder="Amount"
                                      value={
                                        formState.withAmount -
                                        (formState.withAmount *
                                          formState.SavingPrice) /
                                          100
                                      }
                                      readOnly
                                    />
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          <div className="row">
                            <label className="col-lg-4 col-form-label required fw-semibold fs-6">
                              Interest Rate
                            </label>
                            <div className="col-lg-8 fv-row">
                              <input
                                type="number"
                                name="Interestpercentage"
                                className="form-control form-control-lg form-control-solid mb-3 mb-lg-0"
                                placeholder="Interest Rate"
                                value={formState.Interestpercentage}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>

                        <div className="card-footer d-flex justify-content-end py-6 px-9">
                          <button
                            type="submit"
                            className="btn btn-light btn-active-light-primary me-2"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="card card-xxl-stretch mb-5 mb-xl-8">
              <div className="card-header border-0 pt-5">
                <h3 className="card-title align-items-start flex-column">
                  <span className="cardlabel fw-bold fs-3 mb-1">
                    Recent Transactions
                  </span>
                </h3>
                <div className="row">
                  <div className="col-lg-12 fv-row d-flex align-items-center justify-content-center">
                    <div className="d-flex">
                      <select
                        name="filterData"
                        className="form-select flex-grow-1"
                        data-placeholder="Please Select"
                        onChange={handleInputChange}
                        value={formState.filterData}
                      >
                        <option value="all">All</option>
                        <option value="voluntary">Voluntary</option>
                        <option value="mandatory">Mandatory</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="text-end">
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button btn btn-sm btn-SUCCESS"
                    table="table-to-xls"
                    filename="tablexls"
                    sheet="tablexls"
                    buttonText={<i className="fad fa-download fs-1"></i>}
                  />
                </div>
              </div>

              <div className="card-body py-3">
                <div className="table-responsive">
                  <table
                    id="table-to-xls"
                    className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4"
                  >
                    <thead>
                      <tr className="fw-bold text-muted th-title">
                        <th className="min-w-125px">Transaction Date</th>
                        <th className="min-w-150px">Total Amount (ETB)</th>
                        <th className="min-w-150px">Share Amount (ETB)</th>
                        <th className="min-w-150px">Saving Type</th>
                        <th className="min-w-100px">Interest Rate</th>
                        <th className="min-w-100px">Status</th>
                        <th className="min-w-100px">Cancel</th>
                        <th className="min-w-100px">Invoice</th>
                      </tr>
                    </thead>
                    <tbody>
                      {SavingsHistory.map((item) => (
                        <tr key={item.id}>
                          <td>
                            {moment(item.createdAt).format(
                              "MMMM Do YYYY, h:mm:ss a"
                            )}
                          </td>
                          <td>{item.totalAmount.toFixed(2)}</td>
                          <td>{item?.shareAmount || 0}</td>
                          <td>
                            {item.savingType === "mandatory"
                              ? "Mandatory"
                              : "Voluntary"}
                          </td>
                          <td>{item.interest.toFixed(2)}</td>
                          <td>
                            {item.status === "cancel" ? (
                              <span className="text-danger">Cancelled</span>
                            ) : (
                              <span className="text-success">Success</span>
                            )}
                          </td>
                          <td>
                            <button
                              className="btn btn-icon btn-danger1 btn-active-color-primary btn-sm me-1 mr-4"
                             
                             
                             disabled={item.status === "cancel"}
                              onClick={() => {
                                setshowCancelModal(true);
                                settransId(item?._id);
                              }}
                            >
                              <span className="svg-icon svg-icon-3">
                                <img
                                  src="/assets_new/images/cross.png"
                                  width="20px"
                                  alt="Cancel"
                                />
                              </span>
                            </button>
                          </td>
                          <td className="text-center">
                            <button
                              className="btn btn-sm btn-primary"
                              onClick={() =>
                                handleDownloadInvoice(item.invoice)
                              }
                            >
                              Download Invoice
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="col-lg-12 mt-2 text-end">
                  <ReactPaginate
                    PreviousLabel={"Previous"}
                    NextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={
                      "pagination justify-content-end gap-sm-3"
                    }
                    pageClassName={"page-item mx-2"}
                    pageLinkClassName={"page-link"}
                    PreviousClassName={"page-item"}
                    PreviousLinkClassName={"page-link pagestyle"}
                    NextClassName={"page-item"}
                    NextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    activeClassName={"active"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <SuperAgentPasswordVerifyModal
        showModal={showModal}
        setShowModal={setShowModal}
        handlerSubmit={AddSaving}
      />
      <SuperAgentPasswordVerifyModal
        showModal={showCancelModal}
        setShowModal={setshowCancelModal}
        handlerSubmit={handleCancelTransaction}
      />
    </>
  );
}

export default React.memo(AddSavingSuperAgentModal);
