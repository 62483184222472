import { useEffect, useState } from "react";
import "./App.css";

import Webcamera from "./Agent/Webcamera";
import { Route, Routes, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";


// import Home from './Chrome/Home';
// import Audits_branches from './Chrome/Audits_branches';
// import Awaiting_confirmation from './Chrome/awaiting_confirmation';
// import Awaiting_verification from './Chrome/Awaiting_verification';
// import Bank_account_settings from './Chrome/bank_account_settings';
// import Bill_payment_smart_routing from './Chrome/Bill_payment_smart_routing';
// import Customer_settings from './Chrome/Customer_settings';
// import Customer from './Chrome/Customer';
// import Customer_detail from './Chrome/Customer_detail';
// import Customer_send_message from './Chrome/Customer_send_message';
// import Customer_transa_infor from './Chrome/Customer_transa_infor';
// import Customer_transaction_reports from './Chrome/Customer_transaction_reports';
// import Manage_charges from './Chrome/Manage_charges';
// import Default_chrges_setup from './Chrome/Default_chrges_setup';
// import Paycenter_commission_manager_default from './Chrome/Paycenter_commission_manager_default';
// import Dynamic_field_list from './Chrome/Dynamic_field_list';
// import Email_settings from './Chrome/Email_settings';
// import Exchange_rates_list from './Chrome/Exchange_rates_list';
// import Exchange_rates from './Chrome/Exchange_rates';
// import Recurring_charge_fee from './Chrome/Recurring_charge_fee';
// import Prepaid_voucher_batch_history from './Chrome/Prepaid_voucher_batch_history';
// import Supported_services_settings from './Chrome/Supported_services_settings';
// import Manage_api_customers from './Chrome/Manage_api_customers';
// import Manage_transactions_history from './Chrome/Manage_transactions_history';
// import Search_transactions_detail from './Chrome/Search_transactions_detail';
// import Search_transactions from './Chrome/Search_transactions';
// import Organization from './Chrome/Organization';
// import Organization_detail from './Chrome/Organization_detail';
// import Payments_awaiting_processing from './Chrome/Payments_awaiting_processing';
// import Security_settings from './Chrome/Security_settings';

// import AutoAddress from './Agent/components/AutoAddress';

//import jk from './Chrome/demo/Index';

// import C_pay  from './Chrome/C_pay';
// import Digital_user_detail  from './Chrome/Digital_user_detail';
// import OrganizationList from './Chrome/OrganizationList';
// import OrganizationNewDetail from './Chrome/OrganizationNewDetail';

// import Add_organization from './Chrome/Add_organization';
// import Add_customer from './Chrome/Add_customer';
// import Logout from './Chrome/Logout';
// import Protected from './Protected';
// import Customer_3 from './Chrome/Customer_3';
// import Manage_partner_centers from './Chrome/Manage_partner_centers';
// import Register_pay_centers_agent from './Chrome/Register_pay_centers_agent';
// import Edit_api_pay_centers from './Chrome/Edit_api_pay_centers';
// import Aaa_jk from './Chrome/Aaa_jk';
// import Check_box_malti from './Chrome/sub_com/Check_box_malti';

//---------------------Admin--------------//

import Login from "./Admin/Login";
import Otp_login from "./Admin/Otp_login";
import AdminDashbord from "./Admin/AdminDashbord";

//---------------------Admin--------------//

////////new page added Organization
import OrganizationDashboard from "./Organization/Organization";
import AddCustomer from "./Organization/AddCutstomer";
import OtpCustomer from "./Organization/OtpCustomer";
import SuccessCustomerModal from "./Organization/SuccessCustomerModal";
import OrgLogin from "./Organization/OrgLogin";
import CustomersList from "./Organization/CustomersList";
import CustomerTranstionList from "./Organization/CustomerTranstionList";
import AdminCustomer from "./Admin/AdminCustomer";
import ListCustomer from "./Admin/ListCustomer";
import TransactionsList from "./Admin/TransactionsList";
import ListOrgnization from "./Admin/ListOrgnization";
import OrganizationAdd from "./Admin/OrgnizationAdd";
import AgentAdd from "./Organization/AgentAdd";
import AgentList from "./Organization/AgentList";
// import updateLicenses from './Organization/updateLicenses'

import AgentDashbord from "./Agent/AgentDashbord";
import LoginAgent from "./Agent/LoginAgent";
import CustomerAgentAdd from "./Agent/CustomerAgentAdd";
import AgentCustomerList from "./Agent/AgentCustomerList";
import AgentCustomerOtp from "./Agent/AgentCustomerOtp";
import AgentTransactionList from "./Agent/AgentTransactionList";
import AgentChangePassword from "./Agent/AgentChangePassword";
import AdminChangePassword from "./Admin/AdminChangePassword";
import OrgChangePassword from "./Organization/OrgChangePassword";
import AdminSetting from "./Admin/AdminSetting";
import IpAddress from "./Admin/IpAddress";
import CustomerView from "./Admin/CustomerView";
import TransactionView from "./Admin/TransactionView";
import OrgnazationView from "./Admin/OrgnazationView";
import CustmerViewOrg from "./Organization/CustmerViewOrg";
import TransctionViewOrg from "./Organization/TransctionViewOrg";
import AgentViewOrg from "./Organization/AgentViewOrg";
import CutomerViewAgent from "./Agent/CutomerViewAgent";
import TransactionViewAgent from "./Agent/TransactionViewAgent";
import AddBank from "./Organization/AddBank";
import AgentAddAccount from "./Agent/AgentAddAccount";
import AgentAccountList from "./Agent/AgentAccountList";
import MyProfile from "./Admin/MyProfile";
import AgentMyProfile from "./Agent/AgentMyProfile";
import MyProfileOrg from "./Organization/MyProfileOrg";
import OtpOrg from "./Organization/OtpOrg";
import AgentTransactionLimit from "./Admin/AgentTransactionLimit";
import AdminCustomerOtp from "./Admin/AdminCustomerOtp";
import DigitalID from "./Admin/DigitalID";
import AllDigitalID from "./Admin/AllDigitalID";
import CustomerBlockList from "./Admin/CustomerBlockList";
import OrgnizationBlockList from "./Admin/OrgnizationBlockList";
import AgentBlocskList from "./Admin/AgentBlocskList";
import SubadminAdd from "./Admin/SubadminAdd";
import AddRoles from "./Admin/AddRoles";
import UserCustView from "./Admin/UserCustView";

import SetupFees from "./Admin/SetupFees";
import PenddingDID from "./Admin/PenddingDID";
import AgentReport from "./Admin/AgentReport";
import AgentReportView from "./Admin/AgentReportView";
import OrganzationReport from "./Admin/OrganzationReport";
import DIDReport from "./Admin/DIDReport";
import SubAdminList from "./Admin/SubAdminList";
import SubAdminView from "./Admin/SubAdminView";
import Add_admin_document from "./Admin/Add_admin_document";
import RemaningLicenses from "./Organization/RemaningLicenses";
import AgentAdminAdd from "./Admin/AgentAdminAdd";
import AgentAdminList from "./Admin/AgentAdminList";
import CommisionList from "./Admin/CommisionList";
import AgentCommisionList from "./Agent/AgentCommisionList";
import AgentDIDApprovel from "./Agent/AgentDIDApprovel";
import CustmerBankDetail from "./Admin/CustmerBankDetail";
import LicensesOrganzation from "./Admin/LicensesOrganzation";
import performance_repost from "./Admin/performance_repost";
import OrganzationPerfomance from "./Admin/OrganzationPerfomance";
import AgentBlockedDIDs from "./Agent/AgentBlockedDIDs";
import ExportCustomerDIDs from "./Agent/Component/ExportCustomerDIDs";
import ExportCommissionList from "./Agent/Component/ExportCommissionList";
import ExportBlockedList from "./Agent/Component/ExportBlockedList";
import AdminExportCustList from "./Admin/ExportComponent/AdminExportCustList";
import ExportOrgCustList from "./Admin/ExportComponent/ExportOrgCustList";
import ExportAdminBlockedList from "./Admin/ExportComponent/ExportAdminBlockedList";
import OrgCustExport from "./Organization/ExportComponent/OrgCustExport";
import AllOrgnReport from "./Admin/AllOrgnReport";
import LoanApply from "./Organization/LoanComponent/LoanApply";
import CustomerLoanDashboard from "./Organization/LoanComponent/CustomerLoanDashboard";
import LongApply from "./Agent/LoanApply";
import LoanPassList from "./Organization/LoanComponent/LoanPassList";
import LoanPassDashbord from "./Organization/LoanComponent/LoanPassDashbord";
import Customerloanlist from "./Agent/loanlist/Customerloanlist";
import Loanpasslist from "./Agent/loanlist/Loanpasslist";
import CardScore from "./Organization/CreditScoreComponent/CardScore";
import AgentLoanPassDashbord from "./Agent/loanlist/AgentLoanPassDashbord";
import AgentCustLink from "./Agent/LinkComponent/AgentCustLink";
import OrgCustLink from "./Organization/LinkComponent/OrgCustLink";
import AdminCustLink from "./Admin/LinkComponent/AdminCustLink";
import Scanner from "./Agent/Scanner";
import AdminLogsList from "./Admin/LogsComponent/AdminLogsList";
import OrgLogsList from "./Admin/LogsComponent/OrgLogsList";
import AgentLogsList from "./Organization/LogsComponent/AgentLogsList";
import Agentuserwallet from "./Agent/Wallet/Agentuserwallet";
import AgentLatestTransaction from "./Agent/Wallet/AgentLatestTransaction";
import OrgWallet from "./Organization/orgwallet/OrgWallet";
import Orgwebcamera from "./Organization/Orgwebcamera";
import OrgLatesttransaction from "./Organization/orgwallet/OrgLatesttransaction";
import Agent_Performance_repost from "./Organization/Agent_Performance_repost";
import CustomerLogin from "./Customers/CustomerLogin";
import OtpCust from "./Customers/OtpCust";
import CustomerDashbord from "./Customers/CustomerDashbord";
import OrgnzationCustList from "./Customers/OrgnizationComponent/OrgnzationCustList";
import TransactionCustList from "./Customers/TransactionComponent/TransactionCustList";
import CustomerWallet from "./Customers/Wallet/CustomerWallet";
import FuseWallet from "./Customers/Wallet/FuseWallet";
import CustomerLoanApply from "./Customers/loanprocess/CustomerLoanApply";
import Agent_customer_face_add from "./Agent/Agent_customer_face_add";
import Orginization_customer_face_add from "./Organization/Orginization_customer_face_add";
import Customercreateddemo from "./Agent/Customercreateddemo";
import AddCustomerdemo from "./Organization/AddCustomerdemo";
import AdminCustomerDemo from "./Admin/AdminCustomerDemo";
import Admin_customer_face_Add from "./Admin/Admin_customer_face_Add";
import OrgcustomerBlocklist from "./Organization/OrgcustomerBlocklist";
import OrgDigitalId from "./Organization/OrgDigitalId";
import OrdAgentReportView from "./Organization/OrdAgentReportView";
import ORGAgentReportView_performe from "./Organization/ORGAgentReportView_performe";
import Orgviewagent_performance from "./Organization/Orgviewagent_performance";
import Orgselfreport from "./Organization/Orgselfreport";
import Orgdeleteuse from "./Organization/Orgdeleteuse";
import OrgBlocklist from "./Organization/OrgBlocklist";
import Exportorgblocklist from "./Organization/Exportorgblocklist";
import AgentOrgTransactionlimit from "./Organization/AgentOrgTransactionlimit";
import Admin_user_profile_View from "./Admin/Admin_user_profile_View";
import EmpolyList from "./Admin/EmpolyList";
import Agentlist from "./Admin/Agentlist";
import EmpolyeesRegister from "./Organization/EmpolyeesRegister";
import EmpolyListORG from "./Organization/EmpolyList";
import Registerempolyees from "./Admin/Registerempolyees";
import Header from "./Organization/Header";
import Sidebar from "./Organization/Sidebar";
import AgentHeader from "./Agent/Header";
import AgentSidebar from "./Agent/Sidebar";
import AdmitHeader from "./Admin/Header";
import AdmitSidebar from "./Admin/Sidebar";
import Createadmin from "./Organization/Createadmin";
import Viewadmin from "./Organization/Viewadmin";
import Blockagentlist from "./Organization/Blockagentlist";
import UpdateLicence from "./Organization/UpdateLicence";
import ImageReader from "./Admin/ImageReader";
import CreateAgent from "./Organization/SuperAgent/CreateAgent";
import SuperAgentList from "./Organization/SuperAgent/SuperAgentList";
import SuperAgentView from "./Organization/SuperAgent/SuperAgentView";
import SuperAgentBlockList from "./Organization/SuperAgent/SuperAgentBlockList";
import SuperAgentWallet from "./Organization/SuperAgent/SuperAgentWallet";
import SuperAgentWalletHistory from "./Organization/SuperAgent/SuperAgentWalletHistory";
import SuperAgentLogin from "./SuperAgent/SuperAgentLogin";
import SuperAgentSidebar from "./SuperAgent/Sidebar";
import SuperAgentHeader from "./SuperAgent/Header";
import SuperAgentDashboard from "./SuperAgent/SuperAgentDashboard";
import RegisterCustomer from "./SuperAgent/CRM/RegisterCustomer";
import RegisterCustomer2 from "./SuperAgent/CRM/RegisterCustomer2";
import OtpVerifyCutomer from "./SuperAgent/CRM/OtpVerifyCutomer";
import SuperAgentCustomerList from "./SuperAgent/CRM/SuperAgentCustomerList";
import SuperAgentCustomerBlock from "./SuperAgent/CRM/SuperAgentCustomerBlock";
import SuperAgentAwatingList from "./SuperAgent/CRM/SuperAgentAwatingList";
import SuperAgentWalletList from "./SuperAgent/WalletManagment/SuperAgentWalletList";
import SuperAgentLoanPass from "./SuperAgent/LoanManagment/SuperAgentLoanPass";
import OcrHistoryList from "./Admin/LogsComponent/KachaLogs/OcrHistoryList";
import FaceHistoryList from "./Admin/LogsComponent/KachaLogs/FaceHistoryList";
import NotificationView from "./Admin/NotificationComponent/NotificationView";
import LicenseRequest from "./Admin/NotificationComponent/LicenseRequest";
import Performance_repost from "./Admin/performance_repost";
import AgentCustExport from "./Organization/ExportComponent/AgentCustExport";
import SuperAgentloanApply from "./SuperAgent/LoanManagment/SuperAgentloanApply";
import Applyforloan from "./SuperAgent/LoanManagment/Applyforloan";
import SupercustomerView from "./SuperAgent/CRM/SupercustomerView";
import Pendingloan from "./SuperAgent/LoanManagment/Pendingloan";
import AddAgentbysuper from "./SuperAgent/agentManagment/AddAgentbysuper";
import AgentsuperList from "./SuperAgent/agentManagment/AgentsuperList";
import SuperAgentLoanDetails from "./SuperAgent/LoanManagment/SuperAgentLoanDetails";
import LoanApplayAgent from "./Agent/loanlist/LoanApplayAgent";
import AgentLoanEmiDetail from "./Agent/loanlist/AgentLoanEmiDetail";

import SuperAgentSavingsList from "./SuperAgent/SavingsManagment/SuperAgentSavingsList";
import SuperAgentAddLoan from "./Organization/SuperAgent/SuperAgentAddLoan";
import SuperAgentLoanList from "./Organization/SuperAgent/SuperAgentLoanList";
import SuperAgentLoanDetailsOrg from "./Organization/SuperAgent/SuperAgentLoanDetailsOrg";
import SuperAgentEmiDetails from "./Organization/SuperAgent/SuperAgentEmiDetails";
import AddBasicInformation from "./Agent/CustomerComponent/AddBasicInformation";
import AddressInformation from "./Agent/CustomerComponent/AddressInformation";
import AddDocument from "./Agent/CustomerComponent/AddDocument";
import LoanDefulterLevelOne from "./SuperAgent/LoanManagment/LoanDefulterLevelOne";
import Myapplyloanlist from "./SuperAgent/Myloan/Myapplyloanlist";
import Myloandetails from "./SuperAgent/Myloan/Myloandetails";
import Myemidetails from "./SuperAgent/Myloan/Myemidetails";
import SuperAgentListAdmin from "./Admin/SuperAgent/SuperAgentListAdmin";
import SuperAgentViewAdmin from "./Admin/SuperAgent/SuperAgentViewAdmin";
import SuperAgentReport from "./Admin/SuperAgent/SuperAgentReport";
import SuperAgentPerfomance from "./Admin/SuperAgent/SuperAgentPerfomance";
import SuperAgentReportOrg from "./Organization/SuperAgent/SuperAgentReportOrg";
import SuperAgentPerfomaceOrg from "./Organization/SuperAgent/SuperAgentPerfomaceOrg";
import SuperAgentLogsOrg from "./Organization/LogsComponent/SuperAgentLogsOrg";
import UserLogsOrg from "./Organization/LogsComponent/UserLogsOrg";
import EmplyeeDetails from "./Organization/EmplyeeDetails";
import AdminDetailsView from "./Organization/AdminDetailsView";
import NotificationOrg from "./Organization/Notifications/NotificationOrg";
import LoginSuperAgent from "./SuperAgent/LoginSuperAgent";
import AgentSuperReport from "./SuperAgent/agentManagment/AgentSuperReport";
import AgentSuperView from "./SuperAgent/agentManagment/AgentSuperView";
import AgentSuperBlockList from "./SuperAgent/agentManagment/AgentSuperBlockList";
import LoginJdc from "./Jdc/common/LoginJdc";
import DashbordJdc from "./Jdc/common/DashbordJdc";

// import UserLogs from './Organization/LogsComponent/UserLogs'
import JdcSidebar from "./Jdc/common/Sidebar";
import JdcHeader from "./Jdc/common/Header";
import LicenseOrderHistory from "./Organization/LicenseOrderHistory";
import EmployeeEdit from "./Organization/EmployeeEdit";
import AdminEdit from "./Organization/AdminEdit";
import ActiveJdcList from "./Jdc/CRM/ActiveJdcList";
import AdminUnblockListOrg from "./Organization/AdminUnblockListOrg";
import EmployeeUnblockList from "./Organization/EmployeeUnblockList";
import ActiveJdcDetail from "./Jdc/CRM/ActiveJdcDetail";
import ActiveJdcBlockList from "./Jdc/CRM/ActiveJdcBlockList";
import SuperAgentAddAdmin from "./SuperAgent/OrganisationManagment/SuperAgentAddAdmin";
import SuperAgentViewAdminList from "./SuperAgent/OrganisationManagment/SuperAgentViewAdminList";
import SuperAgentOrganistionReport from "./SuperAgent/Reports/SuperAgentOrganistionReport";
import SuperAgentReportAgent from "./SuperAgent/Reports/SuperAgentReportAgent";
import SuperAgentListAgent from "./SuperAgent/Reports/SuperAgentListAgent";
import SuperAgentAdminBlockList from "./SuperAgent/OrganisationManagment/SuperAgentAdminBlockList";
import SuperAgentCustomerExport from "./SuperAgent/Export/SuperAgentCustomerExport";
import SuperAgentExportAgent from "./SuperAgent/Export/SuperAgentExportAgent";
import SuperAgentAdminExport from "./SuperAgent/Export/SuperAgentAdminExport";
import AddDocumentAgentCreated from "./Organization/SuperAgent/AddDocumentAgentCreated";
import Superagentprofile from "./SuperAgent/profile/Superagentprofile";
import Custometrnsaction from "./SuperAgent/CRM/Custometrnsaction";
import Savingcustomer from "./SuperAgent/CRM/Savingcustomer";
import WithoutIdsWallet from "./Admin/WithoutIdsWallet";
import Loancustumerlist from "./Organization/LoanManagement/Loancustumerlist";
import LoanApplycustomerList from "./SuperAgent/LoanManagment/LoanApplycustomerList";
import SuperAgentSavingCustomerlist from "./SuperAgent/LoanManagment/SuperAgentSavingCustomerlist";
import SuperAgentCustomerLoanDashboard from "./SuperAgent/LoanManagment/SuperAgentCustomerLoanDashboard";
import EmiDetailsSuperAgentLoan from "./SuperAgent/LoanManagment/EmiDetailsSuperAgentLoan";
import RejectloanlistCustomer from "./SuperAgent/LoanManagment/RejectloanlistCustomer";
import SuperAgentAloowLoan from "./SuperAgent/LoanManagment/SuperAgentAloowLoan";
import EMIlogs from "./SuperAgent/LoanManagment/EMIlogs";
import EMiDetailsCustomer from "./SuperAgent/LoanManagment/EMiDetailsCustomerSuperAgent";
import EMiDetailsCustomerSuperAgent from "./SuperAgent/LoanManagment/EMiDetailsCustomerSuperAgent";
import RequestLoan from "./SuperAgent/SavingsManagment/RequestLoan";
import SuperAgentAddCustomer from "./SuperAgent/CustomerComponent/SuperAgentAddCustomer";
import SuperAddressInformation from "./SuperAgent/CustomerComponent/SuperAddressInformation";
import SuperAddDocument from "./SuperAgent/CustomerComponent/SuperAddDocument";
import Dashbboardcustomers from "./SuperAgent/CRM/Dashbboardcustomers";
import MandatorySavings from "./SuperAgent/SavingsManagment/MandatorySavings";
import VoluntorySavings from "./SuperAgent/SavingsManagment/VoluntorySavings";
import MonthlyIntrestTransactions from "./SuperAgent/SavingsManagment/MonthlyIntrestTransactions";

import SavingIntrest from "./Admin/SavingIntrest";
import Withdrawalrequest from "./SuperAgent/SavingsManagment/Withdrawalrequest";
import ShareManagementDashboard from "./SuperAgent/ShareManagement/ShareManagementDashboard";
import Shareupdatedadmit from "./Organization/Shareupdatedadmit";
import ShareApplyCustomerList from "./SuperAgent/ShareManagement/ShareApplyCustomerList";
import SharePrice from "./SuperAgent/ShareManagement/SharePrice";
import ChangeIntrestRate from "./SuperAgent/ShareManagement/ChangeIntrestRate";
import ApplyLoan from "./Jdc/CRM/ApplyLoan";
import JdcpendingLoan from "./Jdc/CRM/JdcpendingLoan";
import JdcPassLoans from "./Jdc/CRM/JdcPassLoans";
import JdcAcceptLoan from "./Jdc/CRM/JdcAcceptLoan";
import Jdccooperative from "./Jdc/CRM/Jdccooperative";
import Unionlist from "./Jdc/CRM/Unionlist";
import LoanTypes from "./SuperAgent/LoanManagment/LoanTypes";
import AddProduct from "./SuperAgent/Products/AddProduct";
import ProductManagementList from "./SuperAgent/Products/ProductManagementList";
import ProductStoreManagement from "./SuperAgent/Products/ProductStoreManagement";
import UpdateProduct from "./SuperAgent/Products/UpdateProduct";
import ViewUserCardProduct from "./SuperAgent/Products/ViewUserCardProduct";
import BuyProduct from "./SuperAgent/Products/BuyProduct";
import MyCart from "./SuperAgent/Products/MyCart";
import UserOrder from "./SuperAgent/Products/UserOrder";
import OrderDetail from "./SuperAgent/Products/OrderDetail";
import SellProduct from "./SuperAgent/Products/SellProduct";
import SellngOrder from "./SuperAgent/Products/SellngOrder";
import RoleManagment from "./Admin/SuperAgent/RoleManagment";
import OrderList from "./Jdc/BuyingSelling/OrderList";
import JdcOrderDetail from "./Jdc/BuyingSelling/JdcOrderDetail";
import JdcProduct from "./Jdc/BuyingSelling/JdcProduct";
import JdcSelling from "./Jdc/BuyingSelling/JdcSelling";
import TransactionJdc from "./Jdc/BuyingSelling/TransactionJdc";
import MainDashboard from "./MainDashboard";
import Invoice from "./Invoice/Invoice";
import SuperUserTransaction from "./SuperAgent/Products/SuperUserTransaction";
import LoanInvoice from "./Invoice/LoanInvoice";
import LoanPaidEmiInvoice from "./Invoice/LoanPaidEmiInvoice";
import BuyProductSuper from "./SuperAgent/Products/BuyProductSuper";
import BuyingProductList from "./SuperAgent/Products/BuyingProductList";
import BuyingInvoice from "./Invoice/BuyingInvoice";
import JdcByuingList from "./Jdc/BuyingSelling/JdcByuingList";
import TransactionSuperReports from "./SuperAgent/TransactionsReports/TransactionSuperReports";
import TransactionsPage from "./Jdc/BuyingSelling/TransactionsPage";
import AnalyticMainPage from "./Admin/Analytics/AnalyticMainPage";
import ApplyforPersionLoan from "./SuperAgent/Myloan/ApplyforPersionLoan";
import AllSuperAgentApplyLoanList from "./Organization/SuperAgentApplyLoan/AllSuperAgentApplyLoanList";
import OrgRejectLoanforSuperAgentList from "./Organization/SuperAgentApplyLoan/OrgRejectLoanforSuperAgentList";
import OrgSuperAgentLoanDetails from "./Organization/SuperAgentApplyLoan/OrgSuperAgentLoanDetails";
import OrgApproveLoanList from "./Organization/SuperAgentApplyLoan/OrgApproveLoanList";
import OrgSuperAgentEmiDetails from "./Organization/SuperAgentApplyLoan/OrgSuperAgentEmiDetails";
import OrgShareDashboard from "./Organization/OrgShareManagement/OrgShareDashboard";
import AddRequestToShareBYOrg from "./SuperAgent/ApplyShareOrgManagement/AddRequestToShareBYOrg";
import OrgPenddingRequestShare from "./Organization/OrgShareManagement/PenddingRequestShare/OrgPenddingRequestShare";
import AddExistingLoanpersonal from "./SuperAgent/CRM/AddExistingLoanpersonal";
import ExistingLoanAdd from "./SuperAgent/LoanManagment/ExistingLoanAdd";
import SavingForSuper from "./Organization/SuperAgent/SavingForSuper";
import WithdrawalShareRequest from "./Organization/OrgShareManagement/WithdrawalShareRequest/WithdrawalShareRequest";
import OrgProductsManagemtList from "./Organization/StoreManagementOrg/ProductManagementOrg/OrgProductsManagemtList";
import OrgAddProductManagement from "./Organization/StoreManagementOrg/OrgAddProductManagement/OrgAddProductManagement";
import UpdateOrgProduct from "./Organization/StoreManagementOrg/OrgUpdateProduct/UpdateOrgProduct";
import OrgProductStoreManagementList from "./Organization/StoreManagementOrg/OrgStoreAddProductBySuperAgent/OrgProductStoreManagementList";
import OrgMyStoreProduct from "./Organization/StoreManagementOrg/OrgMyStoreProduct/OrgMyStoreProduct";
import SuperAgentOrderListOrg from "./Organization/StoreManagementOrg/OrgSuperAgentOrderList/SuperAgentOrderListOrg";
import OrgOrderDetail from "./Organization/StoreManagementOrg/ProductManagementOrg/OrgOrderDetail";
import InterestSavingDetail from "./SuperAgent/CRM/InterestSavingDetail";
import PrivateRoute from "./Allrouting/AdminRouting/PrivateRoute";
import AgentPrivatedRouting from "./Allrouting/AgentRouting/AgentPrivatedRouting";
import OrgPrivatedRouting from "./Allrouting/OrgRouting/OrgPrivatedRouting";
import SuperAgentPrivateRoute from "./Allrouting/SuperAgentRouting/SuperAgentPrivateRoute";
import JdcPrivatedRouting from "./Allrouting/JdcRouting/JdcPrivatedRouting";
import NotFound from "./Allrouting/NotFound";
import SavingPageForDashCust from "./SuperAgent/CRM/SavingPageForDashCust";
import SavingPriceUpdated from "./SuperAgent/SavingsManagment/SavingPriceUpdated";
import AdminSavingTransactions from "./Admin/Transactions History/Saving Transactions history/AdminSavingTransactions";
import LoanTransactions from "./Admin/Transactions History/Loan Transactions History/LoanTransactions";
import ShareTransactionHistory from "./Admin/Transactions History/Share Transactions History/ShareTransactionHistory";
import StoreTransactionHistory from "./Admin/Transactions History/Store Transaction History/StoreTransactionHistory";
import SuperLoginLogs from "./Admin/LogsComponent/SuperLoginLogs";
import BlockedIPs from "./Admin/LogsComponent/BlockedIPs";
import JdcCreatedSubadmin from "./Jdc/JdcSupAdmin/JdcCreatedSubadmin";
import JdcSubadminlist from "./Jdc/JdcSupAdmin/JdcSubadminlist";
import JdcSubAdminUpdate from "./Jdc/JdcSupAdmin/JdcSubAdminUpdate";
import AskToAI from "./Invoice/AskToAI";
import NonMemberStore from "./SuperAgent/Products/NonMemberStore";
import JdcProfile from "./Jdc/Profile/JdcProfile";
import AdminDashboardIds from "./Admin/DashboardIds/AdminDashboardIds";
import StroreFrontTrans from "./Admin/StoreFront/StroreFrontTrans";
import UnitMainPage from "./SuperAgent/UnitManagement/UnitMainPage";

const events = [
  "load",
  "mousemove",
  "mousedown",
  "click",
  "scroll",
  "keypress",
  "leave",
  "unload",
];

function App() {
  const [show, setshow] = useState(false);
  const [showagent, setshowagent] = useState(false);
  const [showorg, setshoworg] = useState(false);
  const [showsuper, setshowSuper] = useState(false);
  const [showjdc, setshowJdc] = useState(false);

  const { authType } = useSelector((state) => state.auth);



  const hadleradmin = () => {
    setshow(true);
  };

  const hadleragent = () => {
    setshowagent(true);
  };

  const hadlerorg = () => {
    setshoworg(true);
  };
  const hadlersuper = () => {
    setshowSuper(true);
    console.log("superlogin");
  };
  const hadlerjdc = () => {
    setshowJdc(true);
  };

  let timer;

  const handleLogoutTimer = () => {
    timer = setTimeout(() => {
      // clears any pending timer.
      resetTimer();

      Object.values(events).forEach((item) => {
        window.removeEventListener(item, resetTimer);
      });
      // logs out user
      logoutAction();
    }, 60000000);
  };

  const resetTimer = () => {
    if (timer) clearTimeout(timer);
  };

  useEffect(() => {
    Object.values(events).forEach((item) => {
      window.addEventListener(item, () => {
        resetTimer();
        handleLogoutTimer();
      });
    });
    window.addEventListener("offline", logoutAction);
    return () => {
      events.forEach((event) => window.removeEventListener(event, resetTimer));
      window.removeEventListener("offline", logoutAction);
      if (timer) clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    // Function to handle success in getting location
    const handleSuccess = (position) => {
      const { latitude, longitude } = position.coords;
      console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
      // You can add additional code to use the location data here
    };

    // Function to handle errors in getting location
    const handleError = (error) => {
      switch (error.code) {
        case error.PERMISSION_DENIED:
          console.log("User denied the request for Geolocation.");
          break;
        case error.POSITION_UNAVAILABLE:
          console.log("Location information is unavailable.");
          break;
        case error.TIMEOUT:
          console.log("The request to get user location timed out.");
          break;
        case error.UNKNOWN_ERROR:
          console.log("An unknown error occurred.");
          break;
        default:
          console.log("An unknown error occurred.");
      }
    };

    // Request location access
    const requestLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(handleSuccess, handleError);
      } else {
        console.log("Geolocation is not supported by this browser.");
      }
    };

    // Call the function to request location access
    requestLocation();
  }, []); 

  const logoutAction = () => {
    localStorage.clear();
    window.location.pathname = "/";
  };

  return (
    <div className="App">
      {show ? (
        <>
          <AdmitHeader />
          <AdmitSidebar />
        </>
      ) : (
        ""
      )}
      {showorg ? (
        <>
          <Header showorg={showorg} />
          <Sidebar />
        </>
      ) : (
        ""
      )}

      {showagent ? (
        <>
          <AgentHeader />
          <AgentSidebar />
        </>
      ) : (
        ""
      )}
      {showsuper ? (
        <>
          <SuperAgentHeader />
          <SuperAgentSidebar />
        </>
      ) : (
        ""
      )}

      {showjdc ? (
        <>
          <JdcHeader />
          <JdcSidebar />
        </>
      ) : (
        ""
      )}

      <Routes>
        {/* ////////new page added   Orgnzation   */}
        <Route
          exact
          path="/loginorg"
          element={<OrgLogin hadlerorg={hadlerorg} />}
        />
        <Route element={<OrgPrivatedRouting />}>
          <Route
            exact
            path="/ChromePayDash"
            element={<MainDashboard hadlerorg={hadlerorg} />}
          />

          <Route
            exact
            path="/organization"
            element={<OrganizationDashboard hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/addcustomer"
            element={<AddCustomer hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/Org/products/ManagementList"
            element={<OrgProductsManagemtList hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/Org/Super-Agent/Ordeals"
            element={<SuperAgentOrderListOrg hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/Org/My/cartlist/:_id"
            element={<OrgMyStoreProduct hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/Org/add/products"
            element={<OrgAddProductManagement hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/Org/Updated/products/:_id"
            element={<UpdateOrgProduct hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/Org/Strore/ManagementList/:_id"
            element={<OrgProductStoreManagementList hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/addcustomer/otp"
            element={<OtpCustomer hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/addcustomer/otp/success"
            element={<SuccessCustomerModal hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/customer-list"
            element={<CustomersList hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/Org/superAgent-loan-apply-list"
            element={<AllSuperAgentApplyLoanList hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/Org/superAgent-share-apply-list"
            element={<OrgPenddingRequestShare hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/Org/superAgent-Withdrawal-apply-list"
            element={<WithdrawalShareRequest hadlerorg={hadlerorg} />}
          />

          <Route
            exact
            path="/Org/superAgent-loan-approve-list"
            element={<OrgApproveLoanList hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/Org/superAgent/Emi-loan-detail/:_id/:custId"
            element={<OrgSuperAgentEmiDetails hadlerorg={hadlerorg} />}
          />

          <Route
            exact
            path="/Org/superAgent-loan-details/:_id/:custID"
            element={<OrgSuperAgentLoanDetails hadlerorg={hadlerorg} />}
          />

          <Route
            exact
            path="/Org/superAgent-loan-Reject-list"
            element={<OrgRejectLoanforSuperAgentList hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/transtion-list"
            element={<CustomerTranstionList hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/agent-add"
            element={<AgentAdd hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/agent-list"
            element={<AgentList hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/org-change-password"
            element={<OrgChangePassword hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/org-customer-view/:_id"
            element={<CustmerViewOrg hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/transaction-view-org/:_id"
            element={<TransctionViewOrg hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/agent-view-org/:_id"
            element={<AgentViewOrg hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/Add-Bank"
            element={<AddBank hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/my-profile-org"
            element={<MyProfileOrg hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/otp-org"
            element={<OtpOrg hadlerorg={hadlerorg} />}
          />
          {/* <Route exact path='/update-license' element={<updateLicenses hadlerorg={hadlerorg}/>}/> */}

          <Route
            exact
            path="/update-license"
            element={<UpdateLicence hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/remaning-license"
            element={<RemaningLicenses hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/Share-updated-org"
            element={<Shareupdatedadmit hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/loan-apply-list"
            element={<LoanApply hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/loan-dashboard/:_id/:custID"
            element={<CustomerLoanDashboard hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/loan-pass"
            element={<LoanPassList hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/loan-pass-dashbord/:_id/:custID"
            element={<LoanPassDashbord hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/card-score"
            element={<CardScore hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/org-customer-links"
            element={<OrgCustLink hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/agent-logs-links"
            element={<AgentLogsList hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/OrgWallet/:_id"
            element={<OrgWallet hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/Orgwebcamera/:_id"
            element={<Orgwebcamera hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/OrgLatesttransaction/:_id"
            element={<OrgLatesttransaction hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/Agent_Performance_repost/:_id"
            element={<Agent_Performance_repost hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/Orginization_customer_face_add"
            element={<Orginization_customer_face_add hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/AddCustomerdemo"
            element={<AddCustomerdemo hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/OrgcustomerBlocklist"
            element={<OrgcustomerBlocklist hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/OrgDigitalId"
            element={<OrgDigitalId hadlerorg={hadlerorg} />}
          />

          <Route
            exact
            path="/ORGAgentReportView_performe/:_id"
            element={<ORGAgentReportView_performe hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/loan-management-customer-list"
            element={<Loancustumerlist hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/Orgviewagent_performance/:_id"
            element={<Orgviewagent_performance hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/OrdAgentReportView"
            element={<OrdAgentReportView hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/Orgselfreport"
            element={<Orgselfreport hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/Orgdeleteuse"
            element={<Orgdeleteuse hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/OrgBlocklist"
            element={<OrgBlocklist hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/Exportorgblocklist"
            element={<Exportorgblocklist hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/AgentOrgTransactionlimit"
            element={<AgentOrgTransactionlimit hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/Admin_user_profile_View/:_id"
            element={<Admin_user_profile_View hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/EmpolyeesRegister"
            element={<EmpolyeesRegister hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/EmpolyListORG"
            element={<EmpolyListORG hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/Createadmin"
            element={<Createadmin hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/Viewadmin"
            element={<Viewadmin hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/Blockagentlist"
            element={<Blockagentlist hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/user-logs-org"
            element={<UserLogsOrg hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/employee-details/:_id"
            element={<EmplyeeDetails hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/admin-details-view/:_id"
            element={<AdminDetailsView hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/notification/org"
            element={<NotificationOrg hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/org/license/order-history"
            element={<LicenseOrderHistory hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/employee/edit-org/:_id"
            element={<EmployeeEdit hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/admin/edit-org/:_id"
            element={<AdminEdit hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/admin/unblock/list-org"
            element={<AdminUnblockListOrg hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/employee/unblock/list-org"
            element={<EmployeeUnblockList hadlerorg={hadlerorg} />}
          />

          {/* <Route exact path='/UserLogs' element={<UserLogs hadlerorg={hadlerorg}/>}/>  */}

          {/* //////////------ Super agent Org  --------///// */}

          <Route
            exact
            path="/super-agent-add"
            element={<CreateAgent hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/super-agent-add/AddDocumentAgentCreated"
            element={<AddDocumentAgentCreated hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/super-agent-list"
            element={<SuperAgentList hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/super-agent-view/:_id"
            element={<SuperAgentView hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/Org/ShareDashboard/:_id"
            element={<OrgShareDashboard hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/super-agent-block"
            element={<SuperAgentBlockList hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/super-agent-wallet"
            element={<SuperAgentWallet hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/super-agent-wallet-history/:_id"
            element={<SuperAgentWalletHistory hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/superagent/add-loan/:_id"
            element={<SuperAgentAddLoan hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/superagent/loan-list"
            element={<SuperAgentLoanList hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/superagent/loan-details/:_id"
            element={<SuperAgentLoanDetailsOrg hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/superagent/emi-customer-detail/:_id"
            element={<SuperAgentEmiDetails hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/superagent/super-agent-report-org"
            element={<SuperAgentReportOrg hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/superagent/super-agent-perfomace-org/:_id"
            element={<SuperAgentPerfomaceOrg hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/superagent/super-agent-logs"
            element={<SuperAgentLogsOrg hadlerorg={hadlerorg} />}
          />
          {/*--------------Export Admin---------------- */}
          <Route
            exact
            path="/Export-customer-export"
            element={<OrgCustExport hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/Export-agent-list"
            element={<AgentCustExport hadlerorg={hadlerorg} />}
          />
          <Route
            exact
            path="/super-agent-savings/:_id"
            element={<SavingForSuper hadlerorg={hadlerorg} />}
          />

          <Route
            exact
            path="/org/order/detail/:_id/:agentId"
            element={<OrgOrderDetail hadlerorg={hadlerorg} />}
          />
        </Route>

        {/* //////////------ Super agent   --------///// */}
        <Route
          exact
          path="/superlogin"
          element={<SuperAgentLogin hadlersuper={hadlersuper} />}
        />
        <Route element={<SuperAgentPrivateRoute />}>
          <Route
            exact
            path="/Super-agent-dashbord"
            element={<SuperAgentDashboard hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/Super-agent-Customer"
            element={<RegisterCustomer hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/Super-agent-Customer2"
            element={<RegisterCustomer2 hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/Super-agent/otp-verfiy"
            element={<OtpVerifyCutomer hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/Super-agent/customer-list"
            element={<SuperAgentCustomerList hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/superagent/customer/Existing/Loan/:_id"
            element={<AddExistingLoanpersonal hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/superagent/customer/Existing-Loan-allow/:_id"
            element={<ExistingLoanAdd hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/user-order/list"
            element={<UserOrder hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/sell-order/list"
            element={<SellngOrder hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/Super-agent/Emi-logs-list"
            element={<EMIlogs hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/Super-agent/customer-block"
            element={<SuperAgentCustomerBlock hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/Super-agent/customer-awating"
            element={<SuperAgentAwatingList hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/Super-agent/wallet-list"
            element={<SuperAgentWalletList hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/Super-agent/loan-pass"
            element={<SuperAgentLoanPass hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/SuperAgentloanApply"
            element={<SuperAgentloanApply hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/Applyforloan/:_id"
            element={<Applyforloan hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/Super-Agent-Customer-Loan-Dashboard/:_id/:custID"
            element={
              <SuperAgentCustomerLoanDashboard hadlersuper={hadlersuper} />
            }
          />
          <Route
            exact
            path="/Super-Agent-customer-view/:_id"
            element={<SupercustomerView hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/Super-Agent-pendingloan"
            element={<Pendingloan hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/Super-Agent-Share-Apply-list"
            element={<ShareApplyCustomerList hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/add-agent-by-super"
            element={<AddAgentbysuper hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/superagent-agent-list"
            element={<AgentsuperList hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/superagent-agent-detail/:_id"
            element={<SuperAgentLoanDetails hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/superagent/saving-list"
            element={<SuperAgentSavingsList hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/superagent/loan-defulter/level-one"
            element={<LoanDefulterLevelOne hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/superagent/Myloan/Myapplyloanlist"
            element={<Myapplyloanlist hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/superagent/Add-requiredbyOrg"
            element={<AddRequestToShareBYOrg hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/superagent/Myloan/Myloandetails/:_id"
            element={<Myloandetails hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/superagent/Myloan/Myemidetails/:_id"
            element={<Myemidetails hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/superagent/agent-report/:_id"
            element={<AgentSuperReport hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/superagent/transaction/reports"
            element={<TransactionSuperReports hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/superagent/super-agent-view/:_id"
            element={<AgentSuperView hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/superagent/super-agent-block"
            element={<AgentSuperBlockList hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/superagent/add-admin-by-super"
            element={<SuperAgentAddAdmin hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/superagent/Apply-for-loan"
            element={<ApplyforPersionLoan hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/superagent/view-admin-list"
            element={<SuperAgentViewAdminList hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/superagent/Loan-Apply-customer-List"
            element={<LoanApplycustomerList hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/superagent/Loan-Reject-customer-List"
            element={<RejectloanlistCustomer hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/superagent/Loan-Saving-Customer-list"
            element={<SuperAgentSavingCustomerlist hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/superagent/super-agent-report"
            element={<SuperAgentOrganistionReport hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/superagent/agent-report-agent"
            element={<SuperAgentListAgent hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/superagent/agent-list-super"
            element={<SuperAgentReportAgent hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/superagent/admin-block-list"
            element={<SuperAgentAdminBlockList hadlersuper={hadlersuper} />}
          />
          {/* <Route exact path='/superagent/profile' element={<SuperAgentAdminBlockList hadlersuper={hadlersuper}/>}/>  */}

          {/* Export */}
          <Route
            exact
            path="/superagent/super-agent-active-export"
            element={<SuperAgentCustomerExport hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/superagent/super-agent-export-agent"
            element={<SuperAgentExportAgent hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/superagent/profile"
            element={<Superagentprofile hadlersuper={hadlersuper} />}
          />

          <Route
            exact
            path="/superagent/customer/transaction/:_id"
            element={<Custometrnsaction hadlersuper={hadlersuper} />}
          />

          <Route
            exact
            path="/superagent/share-management-dashboard/:_id"
            element={<ShareManagementDashboard hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/superagent/customer/Emi-Customer-Details/:_id/:_emiId"
            element={<EMiDetailsCustomerSuperAgent hadlersuper={hadlersuper} />}
          />

          <Route
            exact
            path="/superagent/customer/loan-allow/:_id"
            element={<SuperAgentAloowLoan hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/superagent/customer/Saving/:_id"
            element={<Savingcustomer hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/dashboard/customer/saving/:_id"
            element={<SavingPageForDashCust hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/Super-Agent/Emi-loan-detail/:_id/:custId"
            element={<EmiDetailsSuperAgentLoan hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/customer/addCustomer"
            element={<SuperAgentAddCustomer hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/super/customer/address"
            element={<SuperAddressInformation hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/agent/customer/document"
            element={<SuperAddDocument hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/loan-request"
            element={<RequestLoan hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/super/dash-customers"
            element={<Dashbboardcustomers hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/super/saving/mandatory"
            element={<MandatorySavings hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/super/saving/voluntory"
            element={<VoluntorySavings hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/super/saving/Intrest/transactions"
            element={<MonthlyIntrestTransactions hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/super/share/price"
            element={<SharePrice hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/super/saving/price"
            element={<SavingPriceUpdated hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/super/Change-intrest-rate"
            element={<ChangeIntrestRate hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/super/withdrawal/request"
            element={<Withdrawalrequest hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/super/Loan/Type/Setting"
            element={<LoanTypes hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/add/products"
            element={<AddProduct hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/non/member/register"
            element={<NonMemberStore hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/update/products/:_id"
            element={<UpdateProduct hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/sell/products/:_id"
            element={<SellProduct hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/update/products/:_id"
            element={<UpdateProduct hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/saving/detail/:_id"
            element={<InterestSavingDetail hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/ViewUser/CardUpdate/:_id"
            element={<ViewUserCardProduct hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/Buy/Product/:_id/:custId"
            element={<BuyProduct hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/Buy/Products"
            element={<BuyProductSuper hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/products/ManagementList"
            element={<ProductManagementList hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/Strore/ManagementList/:_id"
            element={<ProductStoreManagement hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/user/cart/:_id"
            element={<MyCart hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/order/detail/:_id/:custId"
            element={<OrderDetail hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/super/user/transaction"
            element={<SuperUserTransaction hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/buying/product/list"
            element={<BuyingProductList hadlersuper={hadlersuper} />}
          />

          <Route
            exact
            path="/Invoice/:custId/:_id"
            element={<Invoice hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/Buying-invouve/:_id"
            element={<BuyingInvoice hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/ask-to-open-ai"
            element={<AskToAI hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/LoanInvoive/:_id/:custId"
            element={<LoanInvoice hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/LoanPaidInvoive/:_id/:custId"
            element={<LoanPaidEmiInvoice hadlersuper={hadlersuper} />}
          />
          <Route
            exact
            path="/unit-management"
            element={<UnitMainPage hadlersuper={hadlersuper} />}
          />
        </Route>
        {/* <Route
          exact
          path="/order/detail/:_id/:custId"
          element={<OrderDetail hadlersuper={hadlersuper} />}
        /> */}
        {/* // JdC farmer //hadlerjdc={hadlerjdc} */}
        <Route
          exact
          path="/login-jdc"
          element={<LoginJdc hadlerjdc={hadlerjdc} autType='jdc' />}
        />
        <Route
          exact
          path="/login-ice"
          element={<LoginJdc hadlerjdc={hadlerjdc} autType='ice' />}
        />

        <Route element={<JdcPrivatedRouting />}>
          <Route
            exact
            path={`/${authType}-dashbord`}
            element={<DashbordJdc hadlerjdc={hadlerjdc} />}
          />
          <Route
            exact
            path={`/crm/${authType}-active-list`}
            element={<ActiveJdcList hadlerjdc={hadlerjdc} />}
          />
          <Route
            exact
            path={`/crm/${authType}-active-block`}
            element={<ActiveJdcBlockList hadlerjdc={hadlerjdc} />}
          />
          <Route
            exact
            path={`/crm/${authType}-active-details/:_id`}
            element={<ActiveJdcDetail hadlerjdc={hadlerjdc} />}
          />
          <Route
            exact
            path={`${authType}/apply_loan/:_id`}
            element={<ApplyLoan hadlerjdc={hadlerjdc} />}
          />
          <Route
            exact
            path={`${authType}/pending/list`}
            element={<JdcpendingLoan hadlerjdc={hadlerjdc} />}
          />
          <Route
            exact
            path={`${authType}/pass/list`}
            element={<JdcPassLoans hadlerjdc={hadlerjdc} />}
          />
          <Route
            exact
            path={`${authType}/Accept/loan/:_id/:_id`}
            element={<JdcAcceptLoan hadlerjdc={hadlerjdc} />}
          />
          <Route
            exact
            path={`${authType}/cooperative`}
            element={<Jdccooperative hadlerjdc={hadlerjdc} />}
          />
          <Route
            exact
            path={`${authType}/union`}
            element={<Unionlist hadlerjdc={hadlerjdc} />}
          />

          <Route
            exact
            path={`${authType}/orders`}
            element={<OrderList hadlerjdc={hadlerjdc} />}
          />

          <Route
            exact
            path={`${authType}/order/detail/:_id/:custId`}
            element={<JdcOrderDetail hadlerjdc={hadlerjdc} />}
          />

          <Route
            exact
            path={`${authType}/product/list`}
            element={<JdcProduct hadlerjdc={hadlerjdc} />}
          />
          <Route
            exact
            path={`${authType}/Buying/list`}
            element={<JdcByuingList hadlerjdc={hadlerjdc} />}
          />

          <Route
            exact
            path={`${authType}/selling/order/list`}
            element={<JdcSelling hadlerjdc={hadlerjdc} />}
          />

          <Route
            exact
            path={`${authType}/transaction`}
            element={<TransactionJdc hadlerjdc={hadlerjdc} />}
          />
          <Route
            exact
            path={`${authType}/transaction/data`}
            element={<TransactionsPage hadlerjdc={hadlerjdc} />}
          />
          <Route
            exact
            path={`${authType}/subadmin-created`}
            element={<JdcCreatedSubadmin hadlerjdc={hadlerjdc} />}
          />
          <Route
            exact
            path={`${authType}/update-subadmin`}
            element={<JdcSubAdminUpdate hadlerjdc={hadlerjdc} />}
          />
          <Route
            exact
            path={`${authType}/subadmin-list`}
            element={<JdcSubadminlist hadlerjdc={hadlerjdc} />}
          />

          <Route
            exact
            path={`${authType}/profile`}
            element={<JdcProfile hadlerjdc={hadlerjdc} />}
          />
        </Route>

       

        {/* ////////new page start Admin    */}

        <Route exact path="/" element={<Login />} />
        <Route element={<PrivateRoute />}>
          <Route
            exact
            path="/admin"
            element={<AdminDashbord hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/customer-add"
            element={<AdminCustomer hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/list-customer"
            element={<ListCustomer hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/all-saving-transactions"
            element={<AdminSavingTransactions hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/all-loan-transactions"
            element={<LoanTransactions hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/all-share-transactions"
            element={<ShareTransactionHistory hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/all-store-transactions"
            element={<StoreTransactionHistory hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/transction-list"
            element={<TransactionsList hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/organization-Add"
            element={<OrganizationAdd hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/organization-list-admin"
            element={<ListOrgnization hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/Admin-change-password"
            element={<AdminChangePassword hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/Admin-setting"
            element={<AdminSetting hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/customer-ipaddress"
            element={<IpAddress hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/customer-view-admin/:_id"
            element={<CustomerView hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/transaction-view-admin/:_id"
            element={<TransactionView hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/organzation-view-admin/:_id"
            element={<OrgnazationView hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/my-profile"
            element={<MyProfile hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/agent-transaction-limit"
            element={<AgentTransactionLimit hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/admin-customer-otp"
            element={<AdminCustomerOtp hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/digital-id-list"
            element={<DigitalID hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/digital-approve-list"
            element={<AllDigitalID hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/Customer-block-list"
            element={<CustomerBlockList hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/organisations-block-list"
            element={<OrgnizationBlockList hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/agent-block-list"
            element={<AgentBlocskList hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/subadmin-add"
            element={<SubadminAdd hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/roles-add/:_id"
            element={<AddRoles hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/user-customer-view/:_id"
            element={<UserCustView hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/setup-fees"
            element={<SetupFees hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/pendding-did"
            element={<PenddingDID hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/agent-report"
            element={<AgentReport hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/agent-report-view/:_id"
            element={<AgentReportView hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/organzation-report"
            element={<OrganzationReport hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/did-report"
            element={<DIDReport hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/subadmin-role-list"
            element={<SubAdminList hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/subadmin-view/:_id"
            element={<SubAdminView hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/Add_admin_document/:id"
            element={<Add_admin_document hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/Agent-admin-add"
            element={<AgentAdminAdd hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/Agent-admin-list"
            element={<AgentAdminList hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/customer-commission-list"
            element={<CommisionList hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/custmer-bank-detail/:_id/:custID"
            element={<CustmerBankDetail hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/less-licence"
            element={<LicensesOrganzation hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/performance_repost/:_id"
            element={<Performance_repost hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/oragnzation-performance_repost/:_id"
            element={<OrganzationPerfomance hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/all-orgnzation-report"
            element={<AllOrgnReport hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/admin-customer-links"
            element={<AdminCustLink hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/admin-logs-list"
            element={<AdminLogsList hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/orgnization-logs-list"
            element={<OrgLogsList hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/superagent-login-logs"
            element={<SuperLoginLogs hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/AdminCustomerDemo"
            element={<AdminCustomerDemo hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/Admin_customer_face_Add"
            element={<Admin_customer_face_Add hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/EmpolyList"
            element={<EmpolyList hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/Agentlist"
            element={<Agentlist hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/Registerempolyees"
            element={<Registerempolyees hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/ImageReader"
            element={<ImageReader hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/kacha/ocr"
            element={<OcrHistoryList hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/kacha/face"
            element={<FaceHistoryList hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/notification"
            element={<NotificationView hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/license/request"
            element={<LicenseRequest hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/blocked/ips"
            element={<BlockedIPs hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/admin/super-agent-list"
            element={<SuperAgentListAdmin hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/admin/without-ids-wallet"
            element={<WithoutIdsWallet hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/admin/super-agent-view/:_id"
            element={<SuperAgentViewAdmin hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/admin/super-agent-report"
            element={<SuperAgentReport hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/admin/super-agent-perfomance/:_id"
            element={<SuperAgentPerfomance hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/admin/saving/Interest"
            element={<SavingIntrest hadleradmin={hadleradmin} />}
          />

          <Route
            exact
            path="/role/management/:_id"
            element={<RoleManagment hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/analytic/page"
            element={<AnalyticMainPage hadleradmin={hadleradmin} />}
          />

          {/*--------------Export Admin---------------- */}
          <Route
            exact
            path="/admin-did-customer-export"
            element={<AdminExportCustList hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/Org-customer-export"
            element={<ExportOrgCustList hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/admin-blocked-export"
            element={<ExportAdminBlockedList hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/admin-dashboard-ids"
            element={<AdminDashboardIds hadleradmin={hadleradmin} />}
          />
          <Route
            exact
            path="/store-transactions"
            element={<StroreFrontTrans hadleradmin={hadleradmin} />}
          />
        </Route>
        {/* ////////new page End Admin    */}

        {/* //////////------Agent  --------///// */}

        <Route exact path="/login-agent" element={<LoginAgent />} />

        <Route element={<AgentPrivatedRouting />}>
          <Route
            exact
            path="/agent-dashbord"
            element={<AgentDashbord hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/agent-customer-add"
            element={<CustomerAgentAdd hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/agent-customer-list"
            element={<AgentCustomerList hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/agent-customer-otp"
            element={<AgentCustomerOtp hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/Agent-transction-list"
            element={<AgentTransactionList hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/Agent-change-password"
            element={<AgentChangePassword hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/Agent-customer-view/:_id"
            element={<CutomerViewAgent hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/Agent-transaction-view/:_id"
            element={<TransactionViewAgent hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/Agent-Add-Account/:_id"
            element={<AgentAddAccount hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/Agent-Add-Account-list"
            element={<AgentAccountList hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/agent-my-profile"
            element={<AgentMyProfile hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/Agent-comssion-list"
            element={<AgentCommisionList hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/agent-did-approvel"
            element={<AgentDIDApprovel hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/agent-did-blocked"
            element={<AgentBlockedDIDs hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/longApply/:_id"
            element={<LongApply hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/Customerloanlist"
            element={<Customerloanlist hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/Loanpasslist"
            element={<Loanpasslist hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/agent-loan-pass-dashbord/:_id"
            element={<AgentLoanPassDashbord hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/agent-customer-links"
            element={<AgentCustLink hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/Agentuserwallet/:_id"
            element={<Agentuserwallet hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/scanner"
            element={<Scanner hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/latest-transaction/:_id"
            element={<AgentLatestTransaction hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/AgentWebcamera/:_id"
            element={<Webcamera hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/Agent_customer_face_add"
            element={<Agent_customer_face_add hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/Customercreateddemo"
            element={<Customercreateddemo hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/agent-did-customer"
            element={<ExportCustomerDIDs hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/commision-list-export"
            element={<ExportCommissionList hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/block-list-export"
            element={<ExportBlockedList hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/agent/loan/applay/:_id"
            element={<LoanApplayAgent hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/agent/loan-detail/:_id"
            element={<AgentLoanEmiDetail hadleragent={hadleragent} />}
          />
          <Route
            exact
            path="/agent/emi-customer-detail/:_id"
            element={<EMiDetailsCustomer hadleragent={hadleragent} />}
          />
        </Route>

        {/* Agent Customer Add */}
        {/* <Route
          exact
          path="/agent/customer/Info"
          element={<AddBasicInformation hadleragent={hadleragent} />}
        />
        <Route
          exact
          path="/agent/customer/address"
          element={<AddressInformation hadleragent={hadleragent} />}
        />
        <Route
          exact
          path="/agent/customer/document"
          element={<AddDocument hadleragent={hadleragent} />}
        /> */}

        {/* <Route exact path='/logout' element={<Logout/>}/>
  <Route exact path='/otp_login' element={<Otp_login/>}/>
  <Route exact path='/Aaa_jk' element={<Aaa_jk/>}/>
  <Route exact path='/Check_box_malti' element={<Check_box_malti/>}/>
  <Route exact path='/Home'><Protected cmp = {Home/>}/></Route>
  <Route exact path='/Audits_branches' element={<Audits_branches/>}/>
  
  <Route exact path='/Awaiting_confirmation' element={<Awaiting_confirmation/>}/>
  <Route exact path='/awaiting_verification' element={<Awaiting_verification/>}/>

  <Route exact path='/Bank-account-settings' element={<Bank_account_settings/>}/>
  <Route exact path='/bill-payment-smart-routing' element={<Bill_payment_smart_routing/>}/>

  <Route exact path='/customer-settings' element={<Customer_settings/>}/>
  <Route exact path='/customer' element={<Customer/>}/>
  <Route exact path='/customer_3' element={<Customer_3/>}/>
  <Route exact path='/add-customer' element={<Add_customer/>}/>
  <Route exact path='/customer_detail' element={<Customer_detail/>}/>
  <Route exact path='/customer_send_message' element={<Customer_send_message/>}/>
  <Route exact path='/customer_transa_infor' element={<Customer_transa_infor/>}/>
  <Route exact path='/customer_transaction_reports' element={<Customer_transaction_reports/>}/>

  <Route exact path='/dynamic_field_list' element={<Dynamic_field_list/>}/>
  <Route exact path='/default_chrges_setup' element={<Default_chrges_setup/>}/>

  <Route exact path='/email_settings' element={<Email_settings/>}/>


  <Route exact path='/manage_charges' element={<Manage_charges/>}/>
  <Route exact path='/manage_api_customers' element={<Manage_api_customers/>}/>
  <Route exact path='/manage_transactions_history' element={<Manage_transactions_history/>}/>

  <Route exact path='/exchange_rates_list' element={<Exchange_rates_list/>}/>
  <Route exact path='/exchange_rates' element={<Exchange_rates/>}/>

  <Route exact path='/paycenter_commission_manager_default' element={<Paycenter_commission_manager_default/>}/>
  <Route exact path='/recurring_charge_fee' element={<Recurring_charge_fee/>}/>
  <Route exact path='/prepaid_voucher_batch_history' element={<Prepaid_voucher_batch_history/>}/>
  <Route exact path='/supported_services_settings' element={<Supported_services_settings/>}/>
  <Route exact path='/search_transactions_detail' element={<Search_transactions_detail/>}/>
  <Route exact path='/search_transactions' element={<Search_transactions/>}/>
  <Route exact path='/add-organization' element={<Add_organization/>}/>
  <Route exact path='/organization' element={<Organization/>}/>
  <Route exact path='/organization_detail/' element={<Organization_detail/>}/>
  <Route exact path='/payments_awaiting_processing' element={<Payments_awaiting_processing/>}/>
  <Route exact path='/security_settings' element={<Security_settings/>}/>

  <Route exact path='/manage_partner_centers' element={<Manage_partner_centers/>}/>
  <Route exact path='/register_pay_centers_agent' element={<Register_pay_centers_agent/>}/>
  <Route exact path='/edit_api_pay_centers' element={<Edit_api_pay_centers/>}/>

 */}

        {/* //////////------Customer  --------///// */}
        {/* <Route exact path='/customer-login' element={<CustomerLogin/>}/>
  <Route exact path='/otp-cust' element={<OtpCust/>}/>
  <Route exact path='/customer-dash' element={<CustomerDashbord/>}/>
  <Route exact path='/customer-org-list' element={<OrgnzationCustList/>}/>
  <Route exact path='/customer-transaction-list' element={<TransactionCustList/>}/>
  <Route exact path='/CustomerWallet' element={<CustomerWallet/>}/>
  <Route exact path='/FuseWallet' element={<FuseWallet/>}/>
  <Route exact path='/CustomerLoanApply' element={<CustomerLoanApply/>}/> */}

        <Route exact path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
